import React, { Fragment, FunctionComponent } from 'react'
import { Popover, Transition } from '@headlessui/react'
import { Logo } from '../components/logo'
import { CalendarIcon, ChartBarIcon, ChatIcon, ChevronDownIcon, ClipboardIcon, CreditCardIcon, DocumentTextIcon, IdentificationIcon, MailIcon, MenuIcon, PencilIcon, PhoneIcon, StarIcon, ViewGridIcon, XIcon } from '@heroicons/react/outline'
import { Link } from 'gatsby'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const features = [
  {
    name: 'Customer bookings',
    description: 'Flexible bookings journey at scale',
    href: '/customer-bookings',
    icon: CalendarIcon
  },
  {
    name: 'Inventory and retail management',
    description: 'Streamline your inventory management',
    href: '/inventory-management',
    icon: ClipboardIcon,
  },
  {
    name: 'Email, SMS and waitlist',
    description: 'Organise all of your communication',
    href: '/email-sms-waitlist',
    icon: ChatIcon
  },
  {
    name: 'Payments',
    description: 'Take the stress out of payments',
    href: '/payments',
    icon: CreditCardIcon,
  },
  {
    name: 'Reporting and analytics',
    description: 'Unearth your revenue story',
    href: '/reporting-analytics',
    icon: ChartBarIcon,
  },
  {
    name: 'Membership',
    description: 'Monitor and grow your members',
    href: '/membership',
    icon: IdentificationIcon,
  },
  {
    name: 'Digital intake forms',
    description: 'Collect and store digital intake forms',
    href: '/digital-intake-forms',
    icon: PencilIcon,
  },
  {
    name: 'Integrations',
    description: 'We partner with many platforms',
    href: '/partners',
    icon: ViewGridIcon
  },
]

const resources = [
  {
    name: "Blog",
    description: "Find out whats going on at Trybe and discover something new",
    href: "https://try.be/blog",
    icon: DocumentTextIcon,
  },
]

interface PopoverItem {
  name: string
  description: string
  href: string
  icon: any
}

const ListPopoverInner: React.FC<{ items: PopoverItem[] }> = ({ items = [] }) => (
  <div className="rounded-lg shadow-lg overflow-scroll">
    <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8 lg:grid-cols-2 overflow-hidden">
      {items.map((item) => (
        <Link
          key={item.name}
          to={item.href}
          className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50 transition ease-in-out duration-150"
        >
          <div className="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md bg-violet text-white sm:h-12 sm:w-12">
            <item.icon className="h-6 w-6" aria-hidden="true" />
          </div>
          <div className="ml-4">
            <p className="text-base font-medium text-gray-900">{item.name}</p>
            <p className="mt-1 text-sm text-gray-500">{item.description}</p>
          </div>
        </Link>
      ))}
    </div>
    <div className="p-5 bg-gray-50 sm:p-8">
      <div className="flex space-x-6">
        <a
          href="https://calendly.com/trybe-ricky/15min"
          target="_blank"
          title="Schedule a demo"
          className="-m-3 p-3 flex items-center rounded-md text-base font-medium text-gray-900 hover:bg-gray-100 transition ease-in-out duration-150"
        >
          <PhoneIcon className="flex-shrink-0 h-6 w-6 text-gray-400" aria-hidden="true" />
          <span className="ml-3">Schedule a demo</span>
        </a>
        <Link
          to="/contact-us"
          title="Get in touch"
          className="-m-3 p-3 flex items-center rounded-md text-base font-medium text-gray-900 hover:bg-gray-100 transition ease-in-out duration-150"
        >
          <MailIcon className="flex-shrink-0 h-6 w-6 text-gray-400" aria-hidden="true" />
          <span className="ml-3">Contact us</span>
        </Link>
      </div>
    </div>
  </div>
)
interface TopNavProps {
  hideLinks?: boolean
}

export const TopNav: React.FC<TopNavProps> = ({ hideLinks = false }) => (
  <Popover>
    <div className="fixed bg-grape inset-x-0 top-0 z-50">
      <div className="max-w-7xl mx-auto p-4 sm:p-6">
        <nav className="relative flex items-center justify-between sm:h-10 md:justify-center" aria-label="Global">
          <div className="flex items-center flex-1 md:absolute md:inset-y-0 md:print:right-0 md:left-0">
            <div className="flex items-center justify-between w-full md:w-auto print:mx-auto">
              <Link to="/">
                <span className="sr-only">Trybe</span>
                <Logo variant="dark" />
              </Link>
              {hideLinks === false && <div className="flex items-center md:hidden">
                <Popover.Button className="bg-gray-50 border border-gray-300 shadow-sm rounded-md p-2 inline-flex items-center justify-center hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-violet">
                  <span className="sr-only">Open main menu</span>
                  <MenuIcon className="h-6 w-6" aria-hidden="true" />
                </Popover.Button>
              </div>}
            </div>
          </div>
          {hideLinks === false &&
            <>
              <div className="hidden md:flex md:space-x-10 md:print:hidden">
                <div className="flex space-x-8">
                  <Link
                    to="/about-us"
                    title="About us"
                    className="text-white/75 hover:text-white"
                  >
                    About
                  </Link>
                  <Popover className="relative">
                    {({ open }) => (
                      <>
                        <Popover.Button
                          className={classNames(
                            open ? 'text-white' : 'text-white/75',
                            'group bg-transparent rounded-md inline-flex items-center text-base font-medium hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-violet'
                          )}
                        >
                          <span>Features</span>
                          <ChevronDownIcon
                            className={classNames(
                              open ? 'text-white' : 'text-white/75',
                              'ml-2 h-5 w-5 group-hover:text-white transition ease-in-out duration-150'
                            )}
                            aria-hidden="true"
                          />
                        </Popover.Button>

                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-200"
                          enterFrom="opacity-0 translate-y-1"
                          enterTo="opacity-100 translate-y-0"
                          leave="transition ease-in duration-150"
                          leaveFrom="opacity-100 translate-y-0"
                          leaveTo="opacity-0 translate-y-1"
                        >
                          <Popover.Panel className="absolute z-10 left-1/2 transform -translate-x-1/2 mt-3 px-2 w-screen max-w-md sm:px-0 lg:max-w-3xl">
                            <ListPopoverInner items={features} />
                          </Popover.Panel>
                        </Transition>
                      </>
                    )}
                  </Popover>

                  <Link
                    to="/blog"
                    title="Blog"
                    className="text-white/75 hover:text-white"
                  >
                    Blog
                  </Link>
                  <Link
                    to="/contact-us"
                    title="Contact us"
                    className="text-white/75 hover:text-white"
                  >
                    Contact us
                  </Link>
                </div>
              </div>
              <div className="hidden md:absolute md:flex md:print:hidden md:items-center md:justify-end md:inset-y-0 md:right-0">
                <span className="inline-flex rounded-md shadow">
                  <a
                    href="https://app.try.be"
                    target="_blank"
                    className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-base font-medium rounded-lg text-gray-700 bg-white hover:bg-gray-50"
                  >
                    Log in
                  </a>
                </span>
              </div>
            </>
          }
        </nav>
      </div>

      <Transition
        as={Fragment}
        enter="duration-150 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Popover.Panel
          focus
          className="absolute z-10 top-0 bottom-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
        >
          <div className="rounded-lg shadow-md bg-white ring-1 ring-black ring-opacity-5 overflow-scroll max-h-screen">
            <div className="px-5 py-4 flex items-center justify-between sticky top-0 bottom-0 z-10 bg-white">
              <div>
                <Logo variant="light" />
              </div>
              <div className="-mr-2">
                <Popover.Button className="bg-white border border-gray-300 shadow-sm rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-violet">
                  <span className="sr-only">Close main menu</span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </Popover.Button>
              </div>
            </div>
            <div className='-mt-4'>
              <ListPopoverInner items={features.concat(resources)} />
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </div>
  </Popover>
)
