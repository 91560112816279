import * as React from 'react'
import { Link } from 'gatsby'
import { Logo } from './logo'
import IsoCertification from "../images/SVG/iso-cert-logo.svg"
import SpaAssociation from '../images/spa-association.svg'
import { CookiePolicy } from './CookiePolicy'

const navigation = {
  support: [
    // { name: 'Pricing', href: '#' }, // TODO: Pricing page
    // { name: 'Developers', href: '#' }, // TODO: developer docs
    { name: 'Log In', href: 'https://app.try.be', external: true },
    { name: 'Contact', href: '/contact-us', external: false },
  ],
  company: [
    { name: 'About', href: '/about-us', external: false },
    { name: 'Blog', href: '/blog', external: false },
    { name: 'Branding', href: '/brand-guidelines', external: false },
    { name: 'Careers', href: 'https://apply.workable.com/trybe-2/', external: false },
    { name: 'Demo', href: 'https://calendly.com/trybe-ricky/15min', external: true },
  ],
  legal: [
    { name: 'Privacy', href: '/legal/privacy-policy', external: false },
    { name: 'Terms', href: '/terms-and-conditions.pdf', external: true },
  ],
  social: [
    {
      name: 'LinkedIn',
      href: 'https://www.linkedin.com/company/trybe-spa-leisure-software',
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path
            fillRule="evenodd"
            d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z"
            clipRule="evenodd"
          />
        </svg>
      ),
    },
    {
      name: 'Instagram',
      href: 'https://www.instagram.com/partofthetrybe',
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path
            fillRule="evenodd"
            d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
            clipRule="evenodd"
          />
        </svg>
      ),
    },
    {
      name: 'Twitter',
      href: 'https://twitter.com/trybesoftware',
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
        </svg>
      ),
    },
  ],
}

interface FooterProps {
  hideLinks?: boolean
}

export const Footer: React.FC<FooterProps> = ({ hideLinks = false }) => (
  <footer className="bg-grape-darker print:hidden" aria-labelledby="footer-heading">
    <h2 id="footer-heading" className="sr-only">
      Footer
    </h2>
    <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
      <div className="xl:grid xl:grid-cols-3 xl:gap-8">
        <div className="space-y-8 xl:col-span-1">
          <Logo variant="dark" />
          <p className="text-purple-lighter text-base">
            Next generation Bookings & Business Management Software for the Spa Industry
          </p>
          <div className="flex space-x-6">
            {navigation.social.map((item) => (
              <a key={item.name} href={item.href} className="text-purple-lighter hover:text-white">
                <span className="sr-only">{item.name}</span>
                <item.icon className="h-6 w-6" aria-hidden="true" />
              </a>
            ))}
          </div>
        </div>
        <div className="mt-12 grid grid-cols-2 gap-8 xl:mt-0 xl:col-span-2">
          <div className="md:grid md:grid-cols-2 md:gap-8">
            <div className="mt-12 md:mt-0">
              <h3 className="text-sm font-semibold text-purple-lighter tracking-wider uppercase">Support</h3>
              <ul className="mt-4 space-y-4">
                {navigation.support.map((item) => (
                  <li key={item.name}>
                    {item.external && (
                      <a href={item.href} target="_blank" className="text-purple-lighter hover:text-white">
                        {item.name}
                      </a>
                    )}

                    {!item.external && (
                      <Link to={item.href} className="text-base text-purple-lighter hover:text-white">
                        {item.name}
                      </Link>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="md:grid md:grid-cols-2 md:gap-8">
            <div>
              <h3 className="text-sm font-semibold text-purple-lighter tracking-wider uppercase">Company</h3>
              <ul className="mt-4 space-y-4">
                {navigation.company.map((item) => (
                  <li key={item.name}>
                    {item.external && (
                      <a href={item.href} target="_blank" className="text-purple-lighter hover:text-white">
                        {item.name}
                      </a>
                    )}

                    {!item.external && (
                      <Link to={item.href} className="text-base text-purple-lighter hover:text-white">
                        {item.name}
                      </Link>
                    )}
                  </li>
                ))}
              </ul>
            </div>
            <div className="mt-12 md:mt-0">
              <h3 className="text-sm font-semibold text-purple-lighter tracking-wider uppercase">Legal</h3>
              <ul className="mt-4 space-y-4">
                {navigation.legal.map((item) => (
                  <li key={item.name}>
                    {item.external && (
                      <a href={item.href} target="_blank" className="text-purple-lighter hover:text-white">
                        {item.name}
                      </a>
                    )}

                    {!item.external && (
                      <Link to={item.href} className="text-base text-purple-lighter hover:text-white">
                        {item.name}
                      </Link>
                    )}
                  </li>
                ))}
                <li>
                  <CookiePolicy />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-12 border-t-2 border-purple-lighter border-opacity-25 pt-8 flex items-center justify-between">
        <div className="text-purple-lighter">
          <p className="text-base">
            &copy; {new Date().getFullYear()} Trybe &middot; All rights
            reserved.
          </p>
          <div className="text-xs">
            Five Nines Digital Ltd, 124 City Road, London, EC1V 2NX
          </div>
        </div>
        <div className="flex items-center space-x-4">
          <a
            href="https://www.spa-uk.org"
            title="United Kingdon Spa Association"
            target="_blank"
            style={{ maxHeight: 50 }}
          >
            <img
              src={SpaAssociation}
              height={30}
              alt="United Kingdom Spa Association"
              style={{ maxHeight: 30 }}
            />
          </a>
          <div className="inline-block bg-white">
            <img
              src={IsoCertification}
              height={30}
              alt="ISO27001 Certification"
              style={{ maxHeight: 100 }}
              />
          </div>
        </div>
      </div>
    </div>
  </footer>
)
